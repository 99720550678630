import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Slider from "../components/home/SliderComp";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import useLocalStorage from "../components/useLocalStorage";
import TopBar from "../components/Layout/TopBar";
import SEO from "../components/seo";
import EntryFormButton from "../components/EntryFormButton";
import { css } from "@emotion/core";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import tw from "tailwind.macro";
import GatsbyImage from "gatsby-image";
import hero from "../images/comp/prize-img-2024.jpg";
import hero2 from "../images/comp/hamper-main-2023-new.jpg";
import { MdKeyboardArrowRight } from "react-icons/md";
import * as yup from "yup";
import { FaSpinner } from "react-icons/fa";

const encode = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return formData;
};

const INITIAL = {
  "form-name": "share-story-form-2023",
  "bot-field": "",
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  location: "",
  dogName: "",
  dogAge: "",
  dogBirthday: "",
  aboutDog: "",
  dogPhoto: null,
  dogVideo: null,
  referer: "",
  tos: "",
};

export default function Competition() {
  useEffect(() => {
    function checkUTMParameters() {
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var utmSource = urlParams.get("utm_source");
      var utmMedium = urlParams.get("utm_medium");

      // Check if both utm_source and utm_medium are present and have the correct values
      if (utmSource === "specific_source" && utmMedium === "referrer") {
        // Redirect the user to the desired page only if the redirect flag is not set
        if (!sessionStorage.getItem("redirected")) {
          sessionStorage.setItem("redirected", "true");
          navigate("/submit-story-2024/");
        }
      }
    }

    // Call the function when the DOM is ready
    checkUTMParameters();
  }, []);

  return (
    <>
    <Helmet>
      { /* <SEO title="Share your story" keywords={[`great`, `british`, `dogs`]} /> */ }
      <SEO
        title="greatbritishdogs.co.uk | Share your story"
        keywords={[`great`, `british`, `dogs`]}
      />
    </Helmet>
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <Slider />
      <div>
        <div className="container">
          <div
            className="prose max-w-2xl mx-auto py-12"
            css={css`
              h1 {
                ${tw`text-primary font-slab font-bold text-center `}
              }
              h2 {
                ${tw`text-lightCopy font-sans font-bold text-center `}
              }

              .accent {
                ${tw`text-accent`}
              }
            `}
          >
            <h1 css={css`
                display: none !important;
                `}>Coming soon!<br></br>Win paw-some prizes from Winalot<sup>®</sup>, worth up to £1,000
            </h1>
            <h2 css={css`
                margin-top: 0 !important; margin-bottom: 0 !important; font-size: 1.3em !important;
                `}>
              Is your dog your everyday hero? Share their story for a chance to feature on the Great British Dogs website, and you may even win one of these fantastic prizes...
            </h2>
            <h3 css={css`
                color: #004030!important; margin: 0.5em auto 0.7em auto !important; font-size: 1.7rem !important; text-align: center !important; padding: 0 !important;
                `} className="font-slab">DOG OF THE YEAR</h3>
            <img src={hero} alt="Dog of the Year painting"/>
            <p css={css`
                margin-top: 2em !important;
                `}>
             If your dog is crowned Dog of the Year, you’ll win a £1,000 voucher to spend at <a
                href="https://bit.ly/3eY8pB6"
                className="text-primary font-bold"
                target="_blank"
                css={css`
                color:#004030 !important;
                text-decoration: none !important;
                `}
              >
                {" "}
                The Double Red Duke
              </a>* – a picturesque dog-friendly hotel in the beautiful Cotswolds village of Clanfield in Oxfordshire. The lucky winner will be able to enjoy a relaxing stay at the hotel with their four-legged friend, as well as plenty of opportunities to explore the local area together.</p>
            <h3 css={css`
                color: #004030!important; margin: 0.5em auto 1em auto !important; font-size: 1.7rem !important; text-align: center !important; padding: 0 !important;
                `} className="font-slab">DOG OF THE MONTH</h3>
            <img src={hero2} alt="Dog of the Month prize hamper"/>
            <p css={css`
                margin-top: 2em !important;
                `}>When you enter, you’ll also be eligible for the Dog of the Month competition. If your dog wins, their story and photo will sit in a special slot at the top of the site’s homepage for the whole month! You’ll also win a prize hamper for your dog, including Winalot® dog food pouches, a Great British Dog plaque, a dog bed, and some other goodies.</p>
                <p css={css`
                font-size: 70% !important;
                `}>*There are a limited number of dog-friendly bedrooms available, and a charge of £25 per dog, per night. Dogs are permitted in the bar area of the hotel, but not in the dining rooms or at the Chef’s Counter. To bring your dog, you must contact the Double Red Duke Hotel in advance to ensure that a suitable room and/or table is allocated. Booking is subject to availability. </p>
            <p>
              {/* By entering you agree to our{" "} */}
            <Link
              className="text-primary font-bold"
              to={"/terms-and-conditions"}
              css={css`
              color:#004030 !important;
              text-decoration: none !important;
              `}
            >
              Terms and Conditions
            </Link>{" "}
            apply.</p><a id="form"></a><br />
          </div>
        </div>
      </div>
      <div className="bg-primary ">
        <div className="container ">
          <div
            className=" max-w-2xl  mx-auto  py-12 "
            css={css`
              label {
                ${tw`text-white block mb-2`}
                &.radio {
                  ${tw`inline-flex items-center text-lg w-3/5`}
                  input {
                    ${tw`ml-3 w-8 h-8

                  `}
                  }
                }
              }
              input,
              textarea,
              select {
                ${tw`rounded-xl w-full text-lg py-2 px-4 outline-none block bg-white`}
              }
              select {
                border-right: 10px solid white;
              }
              textarea {
                min-height: 250px;
              }
              .file-selector {
                ${tw`rounded-xl w-full text-lg py-2 px-4 outline-none block bg-white text-black flex items-center justify-between mt-2`}
              }
              .error {
                ${tw`mt-1 text-yellow-500`}
              }
            `}
          >
            <h1
              css={css`
                ${tw`text-primary font-slab font-bold text-left pb-5 text-2xl text-white`} padding-bottom: 2rem !important; text-align: center !important;
              `}
            >Coming soon!</h1>
            {/* <Formik
              initialValues={JSON.parse(data)}
              validationSchema={yup.object({
                email: yup
                  .string()
                  .email("This has to be a valid email")
                  .required("This field is required!"),
                firstName: yup.string().required("This field is required!"),
                lastName: yup.string().required("This field is required!"),
                location: yup.string().required("This field is required!"),
                dogName: yup.string().required("This field is required!"),
                dogAge: yup.string().required("This field is required!"),
                dogBirthday: yup.string().required("This field is required!"),
                aboutDog: yup.string().required("This field is required!"),
                referer: yup.string().required("This field is required!"),
                dogVideo: yup
                  .mixed()
                  .test(
                    "size-limit",
                    "5 MB max file size exceeded!",
                    (value) => {
                      console.log(value);
                      if (!value) {
                        return true;
                      }

                      return value.size < 5 * 1000 * 1024;
                    }
                  ),
                dogPhoto: yup
                  .mixed()
                  .test(
                    "size-limit",
                    "5 MB max file size exceeded!",
                    (value) => {
                      console.log(value);
                      if (!value) {
                        return true;
                      }

                      return value.size < 5 * 1000 * 1024;
                    }
                  )
                  .required("This field is required!"),
                tos: yup.string().required("This field is required!"),
              })}
              onSubmit={(values, actions) => {
                fetch("/", {
                  method: "POST",
                  // headers: {
                  //   "Content-Type": "multipart/form-data; boundary=random",
                  // },
                  body: encode(values),
                })
                  .then(() => {
                    actions.resetForm();
                    setData(JSON.stringify(INITIAL));
                    navigate("/thanks");
                  })
                  .catch((error) => {
                    alert(error);
                  });
              }}
            >
              {({ values, touched, errors, setFieldValue, isSubmitting }) => {
                console.log(errors);
                return (
                  <Form
                    name="share-story-form-2023"
                    method="post"
                    action="/thanks"
                    data-netlify="true"
                    onChange={(e) => {
                      setData(
                        JSON.stringify({
                          ...JSON.parse(data),
                          [e.target.name]: e.target.value,
                        })
                      );
                    }}
                    data-netlify-honeypot="bot-field"
                  >
                    {" "}
                    <p class="hidden">
                      <label id="contact-form-bot-label">
                        Don't fill this out if you're human:{" "}
                        <input
                          name="bot-field"
                          aria-labelledby="contact-form-bot-label"
                        />
                      </label>
                    </p>
                    <input type="hidden" name="form-name" value="share-story-form-2023" />
                    <div className="space-y-8">
                      <div>
                        <label>* Email</label>
                        <Field type="email" name="email"></Field>
                        <ErrorMessage name="email">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label>Phone number (Optional)</label>
                        <Field type="text" name="phone"></Field>
                      </div>
                      <div>
                        <label>* Your first name</label>
                        <Field type="text" name="firstName"></Field>
                        <ErrorMessage name="firstName">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label>* Your last name</label>
                        <Field type="text" name="lastName"></Field>
                        <ErrorMessage name="lastName">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label>* Your location</label>
                        <Field as="select" name="location">
                          <option value="" disabled>
                            -
                          </option>
                          <option value="East Midlands">East Midlands</option>
                          <option value="East Of England"> East Of England</option>
                          <option value="Greater London">Greater London</option>
                          <option value="North East">North East</option>
                          <option value="North West">North West</option>
                          <option value="Northern Ireland"> Northern Ireland</option>
                          <option value="Scotland">Scotland</option>
                          <option value="South East">South East</option>
                          <option value="South West">South West</option>
                          <option value="Wales">Wales</option>
                          <option value="West Midlands">West Midlands</option>
                          <option value="Yorkshire and the Humber">Yorkshire and the Humber </option>
                        </Field>
                        <ErrorMessage name="location">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label>* Your dog’s name</label>
                        <Field type="text" name="dogName"></Field>
                        <ErrorMessage name="dogName">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div>
                        <label>* How old is your dog?</label>
                        <Field as="select" name="dogAge" placeholder="">
                          <option value="" disabled>
                            Years
                          </option>
                          <option value="0">Less than One</option>
                          <option value="1">1 Year</option>
                          <option value="2">2 Years</option>
                          <option value="3">3 Years</option>
                          <option value="4">4 Years</option>
                          <option value="5">5 Years</option>
                          <option value="6">6 Years</option>
                          <option value="7">7 Years</option>
                          <option value="8">8 Years</option>
                          <option value="9">9 Years</option>
                          <option value="10">10 Years</option>
                          <option value="11">11 Years</option>
                          <option value="12">12 Years</option>
                          <option value="13">13 Years</option>
                          <option value="14">14 Years</option>
                          <option value="15">15 Years</option>
                          <option value="16">16 Years</option>
                          <option value="17">17 Years</option>
                          <option value="18">18 Years</option>
                          <option value="19">19 Years</option>
                          <option value="20">20 Years</option>
                        </Field>
                        <ErrorMessage name="dogAge">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label>
                          * Which month do you celebrate their birthday?
                        </label>
                        <Field
                          as="select"
                          name="dogBirthday"
                          placeholder="Month"
                        >
                          <option value="" disabled>
                            Month
                          </option>
                          <option value="JAN">January</option>
                          <option value="FEB">February</option>
                          <option value="MAR">March</option>
                          <option value="APR">April</option>
                          <option value="MAY">May</option>
                          <option value="JUN">June</option>
                          <option value="JUL">July</option>
                          <option value="AUG">August</option>
                          <option value="SEP">September</option>
                          <option value="OCT">October</option>
                          <option value="NOV">November</option>
                          <option value="DEC">December</option>
                        </Field>
                        <ErrorMessage name="dogBirthday">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label>
                          * Now tell us a bit about what makes your dog such a
                          positive influence in your life. Give us as much
                          detail as you can – we'd love to hear about their
                          habits, quirks, etc.
                        </label>
                        <Field as="textarea" name="aboutDog"></Field>
                        <ErrorMessage name="aboutDog">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label htmlFor="dogPhoto" className="relative">
                          * Upload a photo
                          <input
                            onChange={(e) => {
                              if (e.target.files) {
                                setFieldValue("dogPhoto", e.target.files[0]);
                              } else {
                                setFieldValue("dogPhoto", null);
                              }
                            }}
                            type="file"
                            id="dogPhoto"
                            name="dogPhoto"
                            className="  absolute w-0 h-0 top-0 left-0 opacity-0"
                          />
                          <div className="file-selector">
                            <span className="">
                              {values.dogPhoto
                                ? values.dogPhoto.name
                                : "Please select"}
                            </span>

                            <button className="bg-accent pointer-events-none leading-none inline-flex items-center justify-center px-6 py-1  text-white rounded-full relative uppercase font-bench   text-sm ">
                              Upload
                            </button>
                          </div>
                        </label>
                        {touched["dogPhoto"] && (
                          <div className="error">{errors["dogPhoto"]}</div>
                        )}
                      </div>
                      <div>
                        <label htmlFor="dogVideo" className="relative">
                          Upload a video (optional)<br /><em>Max file size 7MB. Recommended length 10-15sec</em>
                          <input
                            onChange={(e) => {
                              if (e.target.files) {
                                setFieldValue("dogVideo", e.target.files[0]);
                              } else {
                                setFieldValue("dogVideo", null);
                              }
                            }}
                            type="file"
                            id="dogVideo"
                            name="dogVideo"
                            className="  absolute w-0 h-0 top-0 left-0 opacity-0"
                          />
                          <div className="file-selector">
                            <span className="">
                              {values.dogVideo
                                ? values.dogVideo.name
                                : "Please select"}
                            </span>

                            <button className="bg-accent pointer-events-none leading-none inline-flex items-center justify-center px-6 py-1  text-white rounded-full relative uppercase font-bench   text-sm ">
                              Upload
                            </button>
                          </div>
                        </label>
                        {touched["dogVideo"] && (
                          <div className="error">{errors["dogVideo"]}</div>
                        )}
                      </div>
                      <div>
                        <label>
                          * Where did you find out about Great British Dogs?
                        </label>
                        <Field
                          as="select"
                          name="referer"
                          placeholder="Please select"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            className="opacity-75"
                          >
                            Please Select
                          </option>
                          <option value="SOCIAL">Social</option>
                          <option value="ONLINE-ADS">
                            Online Advertisements
                          </option>
                          <option value="GF">BBC Good Food</option>
                          <option value="GW">BBC Gardeners’ World</option>
                          <option value="RT">Radio Times</option>
                          <option value="CF">BBC Countryfile</option>
                          <option value="WL">BBC Wildlife</option>
                          <option value="HA">Homes & Antiques</option>
                          <option value="OTHER">Other</option>
                        </Field>
                        <ErrorMessage name="referer">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="text-white text-sm">
                        <p className="mb-2">
                          {" "}
                          By ticking yes below, you consent to your data being
                          shared with Nestlé Purina® Petcare UK&I so that they
                          can send you information and offers. Purina® will only
                          contact you via email for marketing purposes and you
                          can update these preferences or unsubscribe at any
                          time. For more information about how Nestlé Purina®
                          may use your personal data, please see their{" "}
                          <a
                            className="underline"
                            target="_blank"
                            href="https://www.purina.co.uk/privacy/"
                          >
                            Privacy Policy.
                          </a>
                        </p>
                      </div>
                      <div>
                        <label className="radio" css={css`width:100%;`}>
                          <span>
                            *Yes, please send me exclusive offers, content and personalised advice
                            <sup>®</sup>{" "}
                          </span>
                          <Field type="radio" name="tos" value={"Yes"}></Field>
                        </label>
                        <label className="radio" css={css`width:100%;`}>
                          <span>*No, I do not want to receive exclusive offers, content and personalised advice</span>
                          <Field type="radio" name="tos" value={"No"}></Field>
                        </label>
                        <ErrorMessage name="tos">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="text-sm text-white">
                        <p className="mb-3">
                          By entering you agree to our{" "}
                          <Link
                            className=" underline"
                            to={"/terms-and-conditions"}
                          >
                            Terms and Conditions.
                          </Link>
                        </p>
                        <p>
                          For more information on how Immediate Media uses your
                          data, see our{" "}
                          <a
                            className="underline"
                            target="_blank"
                            href="https://policies.immediate.co.uk/privacy/"
                          >
                            Privacy Notice.
                          </a>
                        </p>
                      </div>
                      <div>
                        <button
                          disabled={isSubmitting}
                          className="bg-accent leading-none inline-flex items-center justify-center px-6 py-2  text-white rounded-full relative uppercase font-bench  text-xl outline-none focus:outline-none"
                        >
                          Submit your story
                          {!isSubmitting && (
                            <MdKeyboardArrowRight className="absolute right-0   mr-1" 
                            css={css`
                            top:8px
                            `}/>
                          )}
                          {isSubmitting && (
                            <FaSpinner className="absolute right-0  animate-spin w-4 h-4 mr-1" />
                          )}
                        </button>
                      </div>
                    </div><br /><br />
                  </Form>
                );
              }}
            </Formik><br /><br />*/}
          </div>
        </div>
      </div>
    </>
  );
}